import React from "react";
// import { isMobile } from "helpers/utils";
import { useCopy } from "utility/copyContext";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";



export default function AttendeeHub() {
  const { attendeeHub } = useCopy();
  // const isMobileDevice = isMobile();
  const navigate = useNavigate();
  return (
    <div className="attendeeHub">
      <h1
        className="global_section_title global_section_heading"
        dangerouslySetInnerHTML={{ __html: attendeeHub.title }}></h1>

      {/* Title  */}
      <section className={classNames("global_section_subtitle")}>
        <div className="container">
          {attendeeHub.description.map((data, idx) => {
            return <p key={idx} dangerouslySetInnerHTML={{ __html: data }}></p>;
          })}
        </div>
      </section>

      {/* Items */}
      <section className={classNames("global_section")}>
        <div className="container">
          <div className={classNames("row sections d-flex justify-content-center")}>
            {attendeeHub?.items?.map((data, idx) => {
              return (
                <div class="col-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2 relative resource-section">
                  <div class="resource-section-icon unselectable">
                  <a href={data.url} target="_blank"><img key={idx} src={data.iconUrl} alt={data.title} /></a>
                  </div>
                  <div class="resource-section-title px-4">
                    <a href={data.url} target="_blank"><h4 key={idx} class="title" dangerouslySetInnerHTML={{ __html: data.title }}></h4></a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>

    </div>
  );
};
